import { ChangeEvent, useState } from "react";
import { Plan } from "../../../hooks/useAccount";
import { useAuth } from "../../../hooks/useAuth";
import { useAccount } from "../../../hooks/useAccount";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

export function Profile() {
  const { user, signOut } = useAuth();
  const navigate = useNavigate();
  const { accounts, register } = useAccount(user?.uid || '');
  const [accountType, setAccountType] = useState<Plan | null>(null);
  const [accountName, setAccountName] = useState<string>('');

  const onACTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAccountType(event.target.value as Plan)
  }
  const onACNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const NotAllowedCharactersRegex = /([^A-Za-z0-9\s-])+/g;
    setAccountName(event.target.value.replaceAll(NotAllowedCharactersRegex, ''))
  }

  const onFormSubmit = () => {
    if (user) {
      register({
        name: accountName,
        plan: accountType || 'personal',
        createdOn: (new Date()).toISOString()
      }, user.uid).then(() => {
        navigate('../')
      })
    }
  }

  if (user && accounts?.length === 0) {
    return <>
      <h1 className="text-3xl mb-4"><span className="text-sky-600 font-black block">Welcome,</span>{user.displayName}.</h1>
      <p className="mb-4 text-xl">Lets create you account.</p>
      <form>
        <div>
          <label className="text-slate-800 mb-2 block" htmlFor="AccountTypeRB">What kind of account you want to create?</label>
          <ul>
            <li><input className="mr-2" onChange={onACTypeChange} type="radio" name="AccountTypeRB" value='personal' /> Personal</li>
            <li><input className="mr-2" onChange={onACTypeChange} type="radio" name="AccountTypeRB" value='pro' /> Pro</li>
            <li><input className="mr-2" onChange={onACTypeChange} type="radio" name="AccountTypeRB" value='enterprise' /> Enterprise</li>
          </ul>
        </div>
        <div>
          <label className="text-slate-800 mb-2 mt-4 block" htmlFor="AccountNameInput">Name of your TraQR Link Account?</label>
          <input className="rounded-sm w-full border-[1px] py-1 px-2 my-2" type="text" name="AccountNameInput" title="account name" value={accountName} onChange={onACNameChange} />
        </div>
        <input type="button" className="rounded bg-sky-600 text-white px-4 py-2 my-8" onClick={onFormSubmit} value="Create Account" />
      </form>
    </>
  } else if (accounts) {
    return <>
      <Link className="text-sky-600 inline-block py-2 cursor-pointer" to='..'><FontAwesomeIcon icon={faArrowLeft} /> Back to Code List</Link>
      {accounts.map((a, i) => <div key={i}>{a.name}</div>)}
      <p>
        show profile here
      </p>
      <input type="button" className="rounded bg-sky-600 text-white px-4 py-2 my-8" onClick={signOut} value="Signout" />
    </>
  }
  return <div>Error Loading User</div>
}