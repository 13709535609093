import { GoogleAuthProvider, NextOrObserver, signInWithPopup, signOut, User } from "firebase/auth";
import { auth } from ".";

const provider = new GoogleAuthProvider();

provider.setCustomParameters({
  prompt: "select_account"
})

export async function signInWithGoogle() {
  const { user } = await signInWithPopup(auth, provider);
  return user;
}
export function signOutWithGoogle() {
  signOut(auth);
}

export function registerAuthStateChange(callback: NextOrObserver<User | null>): void {
  auth.onAuthStateChanged(callback)
}