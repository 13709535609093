import logo from "../assets/logo.svg";
interface LogoProps {
  size?: 'small' | 'full'
  className?: string
}
export function Logo({ size = 'full', className = "" }: LogoProps) {
  return <div className={`flex flex-row ${className}`}>
    <img src={logo} alt="TraQR logo" className="w-[38px] h-[38px] mr-4" />
    {size === 'full' && <span className="font-semibold text-2xl mt-1">TRA<span className="text-sky-600">QR</span></span>}
  </div>
}