import { useEffect, useState } from "react"
import { Code } from "../../../entities/codes"
import { EventLog } from "../../../entities/event-logs"
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { TimeLineIcon } from "./TimelineIcon";
dayjs.extend(relativeTime);

interface CodeTimelineProps {
  code: Code
}
export function CodeTimeline({ code }: CodeTimelineProps) {
  const [logs, setLogs] = useState<EventLog[]>([]);
  useEffect(() => {
    if (code.id) {
      EventLog.getEventLogsForEntity('code', code.id).then((data) => setLogs(data.sort((a, b) => {
        return dayjs(b.timestamp).unix() - dayjs(a.timestamp).unix()
      })))
    }
  }, [code.id])

  if (code.id) {
    let lastDate = ''
    return <>
      <h2 className="font-thin text-xl mb-2">Asset activity</h2>
      {logs.map((l, i, arr) => {
        const date = dayjs(l.timestamp).format('DD MMM YY')
        const time = dayjs(l.timestamp).format('h:mm a')
        let showDate = false;
        if (lastDate !== date) {
          lastDate = date
          showDate = true
        }

        const pos = i === 0 ? 'first' : (i === arr.length - 1 ? 'last' : 'middle')

        return <li key={i} className="list-none flex justify-star pb-2">
          <span className="w-[75px] font-thin items-stretch mt-1.5">
            {showDate && date}
          </span>
          <span className="w-4 h-9 items-stretch ">
            <TimeLineIcon position={pos} />
          </span>
          <span className="flex flex-col pl-4">
            <span className="inline-block text-sm text-slate-500 mt-2">
              {time}
            </span>
            <span className="mt-[2px] font-light w-[250px]">
              {l.eventType.charAt(0).toUpperCase() + l.eventType.substr(1).toLowerCase()}
            </span>
            {l.eventType === 'scanned' && <span className="text-xs break-words w-[250px] text-slate-500">
              {l.payload.location?.formatted_address}
            </span>}
            {l.eventType === 'scanned' && <span className="text-xs text-slate-500">
              {l.payload.device.osName} {l.payload.device.osVersion} {l.payload.device.mobileVendor !== 'none' ? `${l.payload.device.mobileVendor} ${l.payload.device.mobileModel}` : ''}
            </span>}
          </span>
        </li>
      })}
    </>
  }

  return null
}