import { useEffect, useState } from "react";
import { Code } from "../entities/codes";

export function useCode(codeId: string | undefined = '') {
  const [code, setCode] = useState<Code>(new Code());

  useEffect(() => {
    if (codeId !== '') {
      Code.get(codeId).then((newCode) => {
        setCode(newCode);
      }).catch(e => console.error(e));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    code,
    error: undefined,
  }
}