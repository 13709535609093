import { Link, useParams, useNavigate } from "react-router-dom";
import { PageContainer } from "../components/PageContainer";
import { useCode } from "../hooks/useCode";
import useGeolocation from "react-hook-geolocation";
import { useEffect, useState } from "react";
import { EventLog } from "../entities/event-logs";
import { deviceType, engineName, engineVersion, browserName, browserVersion, getUA, mobileModel, mobileVendor, osName, osVersion } from "react-device-detect";
import Geocode from "react-geocode";
import { QrReader } from 'react-qr-reader';
import { Navigation } from "../components/Navigation";

export function Track() {
  const { id } = useParams()
  const { latitude, longitude, accuracy } = useGeolocation()
  const { code } = useCode(id);
  const [scanMode, setScanMode] = useState(false);
  const [logMode, setLogMode] = useState(false);
  const [scanLog, setScanLog] = useState<EventLog | null>(null)
  const navigate = useNavigate();

  useEffect(() => {
    if (code.id) {
      EventLog.log('code', code.id || '', 'scanned', {
        location: null,
        device: {
          deviceType, engineName, engineVersion, browserName, browserVersion, UA: getUA, mobileModel, mobileVendor, osName, osVersion
        },

      }).then((ref) => {
        setScanLog(ref);
        setLogMode(true);
      })
    } else {
      setScanLog(null);
      setLogMode(false);
    }

  }, [code.id]);

  useEffect(() => {
    if (latitude && longitude && accuracy) {
      if (scanLog !== null && logMode) {
        Geocode.fromLatLng(latitude.toString(), longitude.toString(), 'AIzaSyAUpWswHiS27WpsGwjlf9taM_lZ6MucoPk').then((response) => {
          scanLog.update({
            location: response.results[0],
            device: {
              deviceType, engineName, engineVersion, browserName, browserVersion, UA: getUA, mobileModel, mobileVendor, osName, osVersion
            },

          })
          setLogMode(false)
        })
      }
    }
  }, [accuracy, latitude, logMode, longitude, scanLog]);

  if (!id || !code.id) {
    return <PageContainer>
      <Navigation />
      {scanMode && <QrReader
        constraints={{
          facingMode: { ideal: 'environment' }
        }}
        onResult={(result, error) => {
          if (!!result) {
            const data = result.getText()
            console.log('Scan result', result.getText());

            const ScanRgx = /https:\/\/traqr\.link\/track\/([0-9a-zA-Z]+)/

            if (ScanRgx.test(data)) {
              setScanMode(false);
              const match = data.match(ScanRgx)
              if (match) {
                console.log('Scanning ', match[1])

                navigate(match[1]);
              }
            }

          }
        }}
        className="w-full"
      />}
      {!scanMode && <button className="justify-self-center mx-auto my-4 px-4 py-2 border-sky-600 border-[1px] text-white rounded flex" onClick={() => setScanMode(true)}>Scan TraQR Code
        <svg className="ml-4" width="24" height="24" viewBox="0 0 64 64" fill="white" xmlns="http://www.w3.org/2000/svg">
          <path d="M7 25.35C7.53043 25.35 8.03914 25.1393 8.41421 24.7642C8.78929 24.3891 9 23.8804 9 23.35V9H23.35C23.8804 9 24.3891 8.78929 24.7642 8.41421C25.1393 8.03914 25.35 7.53043 25.35 7C25.35 6.46957 25.1393 5.96086 24.7642 5.58579C24.3891 5.21071 23.8804 5 23.35 5H7C6.46957 5 5.96086 5.21071 5.58579 5.58579C5.21071 5.96086 5 6.46957 5 7V23.35C5 23.8804 5.21071 24.3891 5.58579 24.7642C5.96086 25.1393 6.46957 25.35 7 25.35Z" fill="white" />
          <path d="M23.35 54.9999H9V40.6499C9 40.1195 8.78929 39.6108 8.41421 39.2357C8.03914 38.8606 7.53043 38.6499 7 38.6499C6.46957 38.6499 5.96086 38.8606 5.58579 39.2357C5.21071 39.6108 5 40.1195 5 40.6499V56.9999C5 57.5303 5.21071 58.039 5.58579 58.4141C5.96086 58.7892 6.46957 58.9999 7 58.9999H23.35C23.8804 58.9999 24.3891 58.7892 24.7642 58.4141C25.1393 58.039 25.35 57.5303 25.35 56.9999C25.35 56.4695 25.1393 55.9608 24.7642 55.5857C24.3891 55.2106 23.8804 54.9999 23.35 54.9999Z" fill="white" />
          <path d="M40.6499 9H54.9999V23.35C54.9999 23.8804 55.2106 24.3891 55.5857 24.7642C55.9608 25.1393 56.4695 25.35 56.9999 25.35C57.5303 25.35 58.039 25.1393 58.4141 24.7642C58.7892 24.3891 58.9999 23.8804 58.9999 23.35V7C58.9999 6.46957 58.7892 5.96086 58.4141 5.58579C58.039 5.21071 57.5303 5 56.9999 5H40.6499C40.1195 5 39.6108 5.21071 39.2357 5.58579C38.8606 5.96086 38.6499 6.46957 38.6499 7C38.6499 7.53043 38.8606 8.03914 39.2357 8.41421C39.6108 8.78929 40.1195 9 40.6499 9Z" fill="white" />
          <path d="M54.9999 40.6499V54.9999H40.6499C40.1195 54.9999 39.6108 55.2106 39.2357 55.5857C38.8606 55.9608 38.6499 56.4695 38.6499 56.9999C38.6499 57.5303 38.8606 58.039 39.2357 58.4141C39.6108 58.7892 40.1195 58.9999 40.6499 58.9999H56.9999C57.5303 58.9999 58.039 58.7892 58.4141 58.4141C58.7892 58.039 58.9999 57.5303 58.9999 56.9999V40.6499C58.9999 40.1195 58.7892 39.6108 58.4141 39.2357C58.039 38.8606 57.5303 38.6499 56.9999 38.6499C56.4695 38.6499 55.9608 38.8606 55.5857 39.2357C55.2106 39.6108 54.9999 40.1195 54.9999 40.6499Z" fill="white" />
          <path d="M60 30H4C3.46957 30 2.96086 30.2107 2.58579 30.5858C2.21071 30.9609 2 31.4696 2 32C2 32.5304 2.21071 33.0391 2.58579 33.4142C2.96086 33.7893 3.46957 34 4 34H60C60.5304 34 61.0391 33.7893 61.4142 33.4142C61.7893 33.0391 62 32.5304 62 32C62 31.4696 61.7893 30.9609 61.4142 30.5858C61.0391 30.2107 60.5304 30 60 30Z" fill="white" />
        </svg>
      </button>}
      {scanMode && <button className="justify-self-center mx-auto my-4 px-4 py-2 border-sky-600 border-[1px] text-white rounded flex" onClick={() => setScanMode(false)}>Cancel</button>}
    </PageContainer>
  }

  return <PageContainer>
    <Navigation />
    <h1 className="font-thin text-3xl"><Link to="/track" relative="path" className="float-right text-sm bg-sky-600 py-1 px-2 rounded mt-1">Scan again</Link>Asset details</h1>
    <div className="bg-gradient-to-br from-indigo-500 to-sky-900 rounded-xl text-white p-4 my-4">
      <h2 className="text-2xl font-light">{code.name}</h2>
      <p className="text-xs font-thin text-sky-200 font-mono">#{code.id}</p>
      <p className="text-sm font-light mt-4">{code.description}</p>
    </div>
    <button className="bg-sky-600 rounded text-white font-light py-1 px-4 mx-auto shadow my-4">Contact Owner</button>
    <div className="rounded font-light mt-8">
      <h3 className="text-2xl font-thin mb-4">Want to create TraQR Tag for your assets?</h3>
      <p className="font-light">Create a free account with <Link to="/" className="text-sky-600 underline">TraQR</Link> to get started.</p>
    </div>
  </PageContainer>
}