import { useNavigate } from "react-router-dom"
import { useAccount } from "../../../hooks/useAccount"
import { useAuth } from "../../../hooks/useAuth"
import notFound from "../../../assets/not-found.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faPrint, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { usePrint } from "../../../hooks/usePrint";

export function CodeList() {
  const { user } = useAuth()
  const { accounts, codes } = useAccount(user?.uid || '')
  const navigate = useNavigate()
  const [search, setSearch] = useState('');
  const [printMode, setPrintMode] = useState(false);
  const [printList, setPrintList] = useState(new Set<string>());
  const { printPage, printing } = usePrint()

  function printTags() {
    try {
      printPage()
    } catch (error) {
      alert(error)
    }
  }

  useEffect(() => {
    if (!printing) {
      console.log('Printing complete');

      setPrintMode(false)
      setPrintList(new Set<string>());
    }
  }, [printing])

  function onTagClicked(id?: string) {
    if (id) {
      if (printMode) {
        if (printList.has(id)) {
          printList.delete(id)
          console.log('removed', id);

        } else {
          printList.add(id)
          console.log('added', id);
        }
        setPrintList(new Set<string>(printList));
        console.log(printList);

      } else {
        navigate(`./code/${id}`)
      }
    }
  }

  if (accounts && accounts.length > 0) {
    return <>
      <h1 className="print:hidden text-3xl text-sky-600 mb-4">My Codes</h1>
      <button className={`print:hidden transition-all duration-300  fixed bottom-8 ${printMode ? '-right-16' : 'right-8'} rounded-full bg-sky-600 w-14 h-14 flex items-center justify-center text-white shadow-2xl`} onClick={() => navigate('./code')}><FontAwesomeIcon icon={faPlus} className="w-6 h-6" /></button>
      <button className={`print:hidden transition-all duration-300  fixed bottom-28 ${printMode ? '-right-16' : 'right-8'} rounded-full bg-sky-600 w-14 h-14 flex items-center justify-center text-white shadow-2xl`} onClick={() => setPrintMode(true)}><FontAwesomeIcon icon={faPrint} className="w-6 h-6" /></button>
      <div className={`print:hidden fixed bottom-0 ${printMode ? 'translate-y-0' : 'translate-y-28'} transition-all duration-300 left-0 right-0 rounded-t-2xl p-4 backdrop-blur-3xl`}>
        <p>{printList.size} tags selected</p>
        <div className="flex">
          <button className="flex-1 px-4 text-center border-[1px] border-sky-600 py-2 mt-4 mr-2 rounded-lg" onClick={() => {
            setPrintMode(false)
            setPrintList(new Set<string>());
          }}>
            Cancel
          </button>
          <button className="flex-1 px-4 text-center border-[1px] border-sky-600 py-2 mt-4 ml-2 rounded-lg bg-green-600" onClick={printTags}>
            <FontAwesomeIcon className="mr-4" icon={faPrint} />
            Print
          </button>
        </div>
      </div>
      {codes && codes.length === 0 && <>
        <div>
          <img className="block" src={notFound} alt="no codes found" />
        </div>
        <button className="justify-self-center flex mx-auto my-12 px-4 py-2 border-solid border-[1px] border-slate-200 bg-white rounded shadow hover:shadow-md" onClick={() => navigate('./code')} >Create New Code</button>
      </>}
      {Array.isArray(codes) && <div className="mb-40 print:grid print:grid-cols-2 print:m-[8mm]">
        <div className="print:hidden flex justify-between px-4 py-2 mx-[-16px] mb-4">
          {codes.length > 0 && <input type='search' name="search" value={search} onChange={e => setSearch(e.target.value)} placeholder="Search" className="print:hidden rounded-full font-light border-[1px] border-sky-600 bg-transparent placeholder:text-cyan-700 px-4 py-2 mr-2 w-full " />}
          {false && <select className="font-light text-sm text-sky-600">
            <option value="all">Show All</option>
            <option value="disabled">Show Disabled</option>
            <option value="lost">Show Lost</option>
            <option value="active">Show Active</option>
          </select>}

        </div>
        {codes.map((code, cIdx) => {
          if (code.name.toLowerCase().search(search.toLowerCase()) === -1 && code.description.toLowerCase().search(search.toLowerCase()) === -1) {
            return null
          }
          return <div key={cIdx} className={`${printList.has(code.id || '') ? '' : 'print:hidden'} flex overflow-hidden font-light px-4 py-2 cursor-pointer rounded-xl mb-3 bg-sky-900 print:w-[95mm] print:m-1`} onClick={() => {
            onTagClicked(code.id)
          }}>
            <div className="w-24 inline-block border-4 border-white rounded my-1 mr-4">
              <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                viewBox={`0 0 256 256`}
                value={`https://traqr.link/track/${code.id}`} />
            </div>
            <div>
              <h4 className="text-xl">
                {/* <span className="float-right text-xs rounded bg-green-700 text-white px-2 pr-3 mt2 -mr-5 ">{code.state.toLocaleUpperCase()}</span> */}
                {code.name} {printList.has(code.id || '') && printMode && <FontAwesomeIcon className="float-right -mr-4 text-green-400 print:hidden" icon={faCircleCheck} />}
              </h4>
              <p className="text-sm text-slate-300 text-ellipsis overflow-hidden w-[201px] h-[60px]">{code.description}</p>
            </div>
          </div>
        })}
      </div>
      }
    </>
  }

  if (accounts && accounts.length === 0) {
    navigate('./profile');
  }

  return <></>
}