import { Link, useParams } from "react-router-dom"
import { useCode } from "../../../hooks/useCode"
import CodeNotFound from "../../../assets/404.svg";
import { CodeForm } from "./CodeForm";
import { CodeTimeline } from "./CodeTimeline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

export function CodeEdit() {
  const { id } = useParams()
  const { code } = useCode(id)
  if (id && code && code.id === undefined) {
    return <>
      <img className="w-48 mx-auto my-4" src={CodeNotFound} alt="Asset not found" />
      <h1 className="text-center text-3xl  font-thin mb-4">Asset not found</h1>
      <p className="mb-4 text-center text-stone-600">The code you are trying to edit is not valid or have been deleted.</p>
      <Link className="bg-sky-600 inline-block mx-auto text-white rounded px-4 py-2" to='..'>Back to Code List</Link>
    </>
  }

  return <>
    <Link className="text-sky-600 inline-block py-2 cursor-pointer" to='..'><FontAwesomeIcon icon={faArrowLeft} /> Back to Code List</Link>
    <CodeForm code={code} />
    <CodeTimeline code={code} />
  </>
}