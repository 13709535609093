interface TimelineIconProps {
  position: 'first' | 'middle' | 'last' | 'skip'
  dotSkip?: boolean
}
export function TimeLineIcon({ position, dotSkip = false }: TimelineIconProps) {
  let startY = -200;
  let endY = 200;
  let startX = 8;
  let endX = 12;
  let midY = 18;

  switch (position) {
    case "first":
      startY = 18
      break;
    case "last":
      endY = 18
      break;

    default:
      break;
  }


  return (<svg>
    {/* <path fill="silver" d="M16 0 L16 16 L0 16 L0 20 L16 20 L16 36 L20 36 L20 20 L36 20 L36 16 L20 16 L20 0 Z" /> */}
    <path fill="silver" d={`M${startX} ${startY} L${startX} ${endY} L${endX} ${endY} L${endX} ${startY} Z`} />
    {!dotSkip && <circle cx={startX + 2} cy={midY} r="6" stroke="silver" strokeWidth="3" fill="#00234B" />}
    {position === 'last' && <path fill="red" d={`M${midY} ${midY} L${midY} ${endY} L${endX} ${endY} L${endX} ${midY} Z`} />}
  </svg>)
}