import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Navigation } from "../../components/Navigation";
import { PageContainer } from "../../components/PageContainer";
import { useAccount } from "../../hooks/useAccount";
import { useAuth } from "../../hooks/useAuth";

export function Manage() {
  const { user } = useAuth();
  const { accounts } = useAccount(user?.uid || '')
  const navigate = useNavigate();

  useEffect(() => {
    if (user == null) {
      navigate('./auth');
    } else if (Array.isArray(accounts) && accounts.length === 0) {
      // Navigate to Create Account
      navigate('./profile')
    }
  }, [user, accounts, navigate])

  return <PageContainer>
    <Navigation type="app" menuItems={[
      {
        link: '/manage',
        label: 'My Codes'
      }
    ]} />
    <Outlet />
  </PageContainer>
}