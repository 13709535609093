import { User } from "firebase/auth";
import { createContext, PropsWithChildren, useEffect, useState } from "react";
import { registerAuthStateChange } from "../firebase";
import { useLocalStorage } from "../hooks/useLocalStorage";

type ContextState = { user: User | null, error: string | null };

export const AuthContext = createContext<ContextState | undefined>(undefined)

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const [user, setUser] = useLocalStorage<User | null>('user', null);
  const [error, setError] = useState<string | null>(null);
  const value = { user, error };
  useEffect(() => {
    registerAuthStateChange((u) => {
      setError(null)
      setUser(u);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};