import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import { Code } from '../../../entities/codes';
import { useAccount } from '../../../hooks/useAccount';
import { useAuth } from '../../../hooks/useAuth';
interface CodeFormProps {
  code: Code
}
export function CodeForm({ code }: CodeFormProps) {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { accounts } = useAccount(user?.uid || '');
  const [editMode, setEditMode] = useState(!code.id);
  const [assetName, setAssetName] = useState(code?.name || '');
  const [assetDescription, setAssetDescription] = useState(code?.description || '');

  function save() {
    if (Array.isArray(accounts) && accounts.length !== 0) {
      code.name = assetName;
      code.ownerAccount = accounts[0].uid
      code.description = assetDescription

      code.save().then(savedCode => {
        console.log('Saved', savedCode);
        code.id = savedCode.id;
        setEditMode(false);
      })
    }
  }

  function cancelEdit() {
    if (code.id)
      setEditMode(false)
    else {
      navigate('../')
    }
  }

  return <>
    {code.id && <h1 className='text-3xl font-thin mb-4'>{code.name}</h1>}
    {!code.id && <h1 className='text-3xl font-bold mb-8'>Add new asset</h1>}
    {editMode && <form>
      <div className='flex flex-col'>
        <label className='text-sm text-light'>Name</label>
        <input className='rounded-sm w-full border-[1px] border-sky-600 bg-transparent placeholder:text-cyan-700 py-1 px-2 my-2 font-light' type='text' name="name" placeholder='Name of your asset' onChange={(e) => setAssetName(e.target.value)} value={assetName} />
      </div>
      <div className='flex flex-col'>
        <label className='text-sm text-light'>Description</label>
        <textarea className='rounded-sm w-full border-[1px] py-1 px-2 my-2 font-light border-sky-600 bg-transparent placeholder:text-cyan-700' name="description" placeholder='Any additonal text you want to display when scanned' onChange={(e) => setAssetDescription(e.target.value)} value={assetDescription} />
      </div>
      <div className='flex justify-between'>
        <input type="button" className="my-8 px-4 py-1 border-solid border-[1px] border-sky-600 rounded shadow hover:shadow-md" onClick={cancelEdit} value="Cancel" />
        <input type="button" className="my-8 px-4 py-1 border-solid border-[1px] border-sky-600 bg-sky-600 text-white rounded shadow hover:shadow-md" onClick={save} value="Save" />
      </div>
    </form>}
    {!editMode && <>
      <p className='text-slate-600 font-light'>{code.description}</p>
      <div className='flex justify-between space-x-2'>
        <input type="button" className="flex-1 my-8 px-4 py-1 border-solid border-[1px] border-sky-600 rounded shadow hover:shadow-md" onClick={() => setEditMode(true)} value="Edit" />
        <Link to={`/track/${code.id}`} className="flex-1 my-8 px-4 py-1 border-solid border-[1px] border-sky-600 bg-sky-600 text-white rounded shadow hover:shadow-md text-center" target="_blank">Preview</Link>
        {/* <input type="button" className="flex-1 my-8 px-4 py-1 border-solid border-[1px] border-sky-600 bg-sky-600 text-white rounded shadow hover:shadow-md" onClick={() => navigate(`/track/${code.id}`)} value="Preview" /> */}
      </div>
    </>

    }
  </>
}