import { Link, NavLink } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightToBracket } from "@fortawesome/free-solid-svg-icons";
import { Logo } from "./Logo";
import { useAuth } from "../hooks/useAuth";

interface MenuItem {
  label: string,
  link: string,
  end?: boolean
}


interface NavigationProps {
  type?: 'web' | 'app',
  menuItems?: MenuItem[]
}

export function Navigation({ type = 'web', menuItems = [] }: NavigationProps) {
  const { user } = useAuth()

  return <nav className="flex justify-between justify-items-stretch pb-4 print:hidden">
    <Link to='/' className="flex-1" >
      <Logo size={type === 'app' ? 'small' : 'full'} />
    </Link>
    {menuItems.map((m, mIdx) => {
      return <NavLink key={mIdx} to={m.link} end={m.end} className="py-1 px-3 mt-1 font-light uppercase text-sky-200">
        {m.label}
      </NavLink>
    })}
    {!user && <Link to="./manage" className="rounded py-1 px-3 font-light text-center">
      <FontAwesomeIcon icon={faRightToBracket} />
    </Link>}
    {user && <Link to="/manage/profile" relative={"path"} className="rounded py-1 px-3 font-light text-center -mt-1">
      <img referrerPolicy="no-referrer" src={user.photoURL || ''} className="rounded-full border-2 border-white w-[38px]" alt={user.displayName || ''} />
    </Link>}
  </nav>
}