import { addDoc, CollectionReference, DocumentData, DocumentReference, getDocs, query, updateDoc, where } from "firebase/firestore"
import { createCollection } from "../firebase"
type EventType = 'created' | 'updated' | 'scanned' | 'state-changed'
type EntityType = 'code' | 'account' | 'user'

function getEntityId(entityType: EntityType, entityId: string) {
  return `${entityType}-${entityId}`;
}

export class EventLog {
  private static collection: CollectionReference = createCollection<EventLog>('event-logs')

  static async log(entityType: EntityType, entityId: string, eventType: EventType, payload: any = {}) {

    const newEventLog = new EventLog();
    newEventLog.entityId = entityId
    newEventLog.payload = payload
    newEventLog.eventType = eventType
    console.log('Logging', {
      entityId: getEntityId(entityType, entityId),
      eventType,
      payload,
      timestamp: newEventLog.timestamp
    });

    newEventLog.ref = await addDoc(this.collection, {
      entityId: getEntityId(entityType, entityId),
      eventType,
      payload,
      timestamp: newEventLog.timestamp
    });

    newEventLog.id = newEventLog.ref.id
    return newEventLog;
  }

  static async getEventLogsForEntity(entityType: EntityType, entityId: string): Promise<EventLog[]> {
    const q = query(EventLog.collection, where("entityId", "==", getEntityId(entityType, entityId)));

    const querySnapshot = await getDocs(q);
    const returnEventLogs: EventLog[] = []
    querySnapshot.forEach((doc) => {
      const newEventLog = new EventLog();
      const data = doc.data();
      newEventLog.id = doc.id
      newEventLog.entityId = data.entityId
      newEventLog.eventType = data.eventType
      newEventLog.payload = data.payload
      newEventLog.timestamp = data.timestamp

      returnEventLogs.push(newEventLog);
    });
    return returnEventLogs;
  }

  private ref: DocumentReference<DocumentData> | undefined

  id?: string = undefined
  entityId: string = ''
  eventType: EventType = 'created'
  payload: any = {}
  timestamp: string = (new Date()).toISOString()

  async update(payload: any = {}) {
    if (this.ref) {
      await updateDoc(this.ref, {
        entityId: this.entityId,
        eventType: this.eventType,
        payload,
        timestamp: (new Date()).toISOString()
      });
    }

  }
}