import { addDoc, CollectionReference, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore"
import { createCollection } from "../firebase"
import { Account } from "../hooks/useAccount"
import { EventLog } from "./event-logs";

export class Code {
  private static collection: CollectionReference = createCollection<Code>('codes')

  static async get(id: string) {
    const newDocRef = await getDoc(doc(this.collection, id));
    const newCode = new Code()
    const data = newDocRef.data();
    if (data) {
      newCode.id = newDocRef.id
      newCode.ownerAccount = data.ownerAccount
      newCode.name = data.name
      newCode.description = data.description
    } else {
      throw new Error("Code not found");
    }

    return newCode;
  }

  static async getCodesForAccount(accountId: string): Promise<Code[]> {
    const q = query(Code.collection, where("ownerAccount", "==", accountId));

    const querySnapshot = await getDocs(q);
    const returnCodes: Code[] = []
    querySnapshot.forEach((doc) => {
      const newCode = new Code();
      const data = doc.data();
      newCode.id = doc.id
      newCode.ownerAccount = data.ownerAccount
      newCode.name = data.name
      newCode.description = data.description
      returnCodes.push(newCode);
    });
    return returnCodes;
  }

  id?: string = undefined
  ownerAccount: Account['uid']
  name: string = ''
  description: string = ''
  state: 'active' | 'lost' | 'disabled' = 'active'

  async save() {
    if (this.id) {
      // Update
      await updateDoc(doc(Code.collection, this.id), {
        ownerAccount: this.ownerAccount,
        name: this.name,
        description: this.description,
        state: this.state
      })

      await EventLog.log('code', this.id, 'updated');
    } else {
      const newDocRef = await addDoc(Code.collection, {
        ownerAccount: this.ownerAccount,
        name: this.name,
        description: this.description,
        state: this.state
      })

      await EventLog.log('code', newDocRef.id, 'created')

      this.id = newDocRef.id;
    }

    return this;
  }

}