// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { Analytics, getAnalytics, isSupported } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAUpWswHiS27WpsGwjlf9taM_lZ6MucoPk",
  authDomain: "traqr-link.firebaseapp.com",
  projectId: "traqr-link",
  storageBucket: "traqr-link.appspot.com",
  messagingSenderId: "843019433973",
  appId: "1:843019433973:web:be530e92fad6b62dc892bc",
  measurementId: "G-YRNGX25PGQ",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const database = getFirestore(app);

export let analytics: Analytics = {
  app: app
  // logEvent: () => { },
  // setCurrentScreen: () => { },
  // setUserId: () => { },
};
isSupported().then((s) => {
  if (s) {
    analytics = getAnalytics(app)
  }
})

export * from './Auth';
export * from './Firestore';
