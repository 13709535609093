import { createBrowserRouter } from "react-router-dom";
import { Home } from "./pages/Home";
import { Manage } from "./pages/Manage";
import { CodeEdit } from "./pages/Manage/Auth/CodeEdit";
import { CodeList } from "./pages/Manage/Auth/CodeList";
import { Login } from "./pages/Manage/Auth/Login";
import { Profile } from "./pages/Manage/Auth/Profile";
import { Track } from "./pages/Track";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/track",
    element: <Track />,
  },
  {
    path: "/track/:id",
    element: <Track />,
  },
  {
    path: "/manage",
    element: <Manage />,
    children: [
      {
        index: true,
        element: <CodeList />
      },
      {
        path: 'auth',
        element: <Login />
      },
      {
        path: 'profile',
        element: <Profile />
      },
      {
        path: 'code',
        element: <CodeEdit />
      },
      {
        path: 'code/:id',
        element: <CodeEdit />
      }
    ]
  },
]);