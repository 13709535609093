import { PageContainer } from "../components/PageContainer";
import { Typography } from "../components/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Navigation } from "../components/Navigation";


export function Home() {
  return <PageContainer>
    <Navigation menuItems={[
      {
        link: './track',
        label: 'Track',
        end: true
      }
    ]} />
    <h1 className={`mt-10 block ${Typography.Headline}`}>Track your things with<br />QR codes</h1>
    <p className={`mt-3 mb-10 block ${Typography.SubHeadline}`}>No App. No Batteries. No devices.</p>

    <Link to='./manage' className="rounded-lg bg-sky-600 p-3 mb-8 text-center">GENERATE YOUR FREE CODE</Link>
    <Link to='./track' className="rounded-lg border-sky-600 border-[1px] p-3 mb-8 text-center">SCAN A CODE <FontAwesomeIcon className="ml-3" icon={faMagnifyingGlass} /></Link>
    <p className={Typography.Body}>TraQR lets you create custom QR codes which can be used to track things, like universal asset tags. You can be contacted if someone finds your thing and scans a TraQR code without exposing your contact details to anyone.</p>
    <p className={Typography.Body}>You can print codes yourself or buy heavy duty QR codes from us and link them with your things.</p>
    <hr className="my-10 border-sky-600" />
    <h2 className={`${Typography.Heading}`}>How it works?</h2>
    <p className={Typography.Body}>With TraQR Link, you can create a QR Code which can be configured to one of the tracking type.</p>
    <p className={Typography.Body}>Then that created QR Code can be pasted on your things.</p>
    <p className={Typography.Body}>When someone scans that QR Code, they can know if that thing is lost or not. If lost, they can contact you without your contact information being exposed to them.</p>
    <hr className="my-10 border-sky-600" />
    <h2 className={`${Typography.Heading}`}>TraQR Code Features</h2>
    <h3 className={`${Typography.SubHeading}`}>Asset Tag</h3>
    <p className={Typography.Body}>Asset tags can be used like name tags. On scanning, they display public details of that asset. Optionally, user scanning it can contact the owner by sending a text message with their current location and contact information.</p>
    <h3 className={`${Typography.SubHeading}`}>Lost Mode</h3>
    <p className={Typography.Body}>If you lose your asset, then you can mark it as 'Lost'. Lost assets will display that it has been lost, can a form to message the owner to be contacted for retrieval.</p>
    <h3 className={`${Typography.SubHeading}`}>Scan timeline</h3>
    <p className={Typography.Body}>Whenever a tag ios scanned, we track that even and try to collect device infoirmation, location and time of scanning. This information is then used to display a timeline of your asset's activity.</p>
  </PageContainer>
}