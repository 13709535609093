import React from "react";
import { RouterProvider } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import { router } from "./router";

export function App() {
  return <React.StrictMode>
    <AuthProvider>
      <div className='min-h-screen w-screen bg-gradient-to-tr from-[#00234B] to-[#00070F] text-white'>
        <RouterProvider router={router} />
      </div>
    </AuthProvider>
  </React.StrictMode>
}