import { useEffect, useState } from "react"

export function usePrint() {
  const [printing, setPrinting] = useState(false)

  const callback = () => setPrinting(false)

  useEffect(() => {
    if (printing) {
      window.addEventListener('afterprint', callback)
      window.print()
    }

    return () => {
      window.removeEventListener('afterprint', callback)
    }

  }, [printing])
  return {
    printPage: () => {
      setPrinting(true)
    },
    printing
  }
}