import { User } from "firebase/auth"
import { addDoc, collection, DocumentData, documentId, getDocs, query, QueryDocumentSnapshot, SnapshotOptions, where, WithFieldValue } from "firebase/firestore"
import { useEffect, useState } from "react"
import { Code } from "../entities/codes"
import { database } from "../firebase"
export type Plan = 'personal' | 'pro' | 'enterprise'

export interface Account {
  uid?: string,
  name: string,
  plan: Plan,
  createdOn: string
}

export interface AccountUser {
  uid?: string,
  userId: User['uid'],
  accountId: Account['uid']
  role: 'owner' | 'shared',
  createdOn: string
}

export interface CachedUser {
  uid: User['uid']
  displayName: User['displayName']
  email: User['email']
  photoUrl: User['photoURL']
}

const AccountUsersRef = collection(database, "AccountUsers").withConverter({
  toFirestore(data: WithFieldValue<AccountUser>): DocumentData {
    return { ...data };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): AccountUser {
    const data = snapshot.data(options)!;
    return {
      userId: data.userId.toString(),
      accountId: data.accountId.toString(),
      role: data.role.toString(),
      createdOn: data.createdOn.toString()
    };
  }
});

const AccountRef = collection(database, "Accounts").withConverter({
  toFirestore(data: WithFieldValue<Account>): DocumentData {
    return { ...data };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Account {
    const data = snapshot.data(options)!;
    return {
      uid: snapshot.id,
      name: data.name,
      plan: data.plan,
      createdOn: data.createdOn.toString()
    };
  }
});

export function useAccount(userId: User['uid']) {
  let [accounts, setAccounts] = useState<Account[] | null>(null)
  let [codes, setCodes] = useState<Code[]>([])

  async function register(account: Account, owner: User['uid']): Promise<string> {
    const AccountRef = await addDoc(collection(database, "Accounts"), {
      ...account
    });

    await addDoc(collection(database, "AccountUsers"), {
      userId: owner,
      accountId: AccountRef.id,
      role: 'owner',
      createdOn: (new Date()).toISOString()
    });

    return AccountRef.id
  }

  async function createCode(account: Account, owner: User['uid']) {
    throw new Error("Not Implimented");

  }

  useEffect(() => {
    if (userId) {
      const AUQuery = query(AccountUsersRef, where("userId", "==", userId));

      getDocs(AUQuery).then((data) => {
        const accountLinks: AccountUser[] = []
        let accountLinksACIds: (string | undefined)[] = []
        data.forEach(d => {
          const { userId, role, accountId, createdOn } = d.data();
          accountLinks.push({
            userId: userId.toString(), role: role === 'shared' ? 'shared' : 'owner', accountId: accountId?.toString(), createdOn: createdOn.toString()
          });
          accountLinksACIds = accountLinks.map(ac => ac.accountId)
        })

        if (accountLinks.length > 0) {
          const ACQuery = query(AccountRef, where(documentId(), "in", accountLinksACIds));
          getDocs(ACQuery).then((acdata) => {
            const connectedAccounts: Account[] = []
            acdata.forEach(ac => {
              const { name, plan, createdOn } = ac.data()
              connectedAccounts.push({
                uid: ac.id,
                name: name.toString(),
                plan: plan as Plan,
                createdOn: createdOn.toString()
              })
            })

            setAccounts(connectedAccounts);
            if (connectedAccounts[0] && connectedAccounts[0].uid) {
              Code.getCodesForAccount(connectedAccounts[0].uid).then(codes => setCodes(codes))
            }
          })
        } else {
          setAccounts([]);
          setCodes([])
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    accounts,
    codes,
    register,
    createCode
  }
}