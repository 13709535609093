import { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { signInWithGoogle, signOutWithGoogle } from "../firebase";

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(
      "useFirebaseAuth must be used within a FirebaseAuthProvider"
    );
  }
  function signIn() {
    signInWithGoogle()
  }
  function signOut() {
    signOutWithGoogle()
      ;
  }

  return {
    user: context.user,
    error: context.error,
    signIn,
    signOut
  }
}